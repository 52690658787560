import { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Icon } from '@iconify/react'
import './settings-modal.css'
import { RotatingLines } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-modal'
import schoolService from '../../../../services/api/school'

const schema = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  email: yup
    .string()
    .email('Invalid email')
    .required('Work Email Address is required'),
  position: yup.string().required('Position is required'),
})

const SettingsAddEmailNotificationModal = ({
  closeModal,
  openSuccessModal,
}) => {
  const [modalIsOpenSuccess, setModalIsOpenSuccess] = useState(false)
  const mutationTriggered = useRef(false)
  const queryClient = useQueryClient()

  const closeSuccessModal = () => {
    setModalIsOpenSuccess(false)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const mutation = useMutation({
    mutationFn: schoolService.emailAdminInvite,
    onSuccess: (data) => {
      closeModal()
      openSuccessModal()
      queryClient.invalidateQueries(['school-email-team'])
      mutationTriggered.current = false
    },
    onError: (error) => {
      toast.error(error?.message)
      mutationTriggered.current = false
    },
  })

  const onSubmit = (data) => {
    if (!mutationTriggered.current) {
      mutationTriggered.current = true

      // Combine first_name and last_name to create fullName
      const fullName = `${data.first_name} ${data.last_name}`

      // Create the data object to send in the mutation
      const payload = {
        fullName,
        email: data.email,
        position: data.position,
      }

      mutation.mutate(payload)
    }
  }

  return (
    <div className=''>
      <div className='edit-course-container'>
        <div className='header' style={{ border: 'none' }}>
          <p
            className='team-heading'
            style={{ color: '#18181B', fontSize: '20px' }}
          >
            Add New Team
          </p>
        </div>

        <p
          className='sub-heading'
          style={{ color: '#18181B', fontSize: '14px' }}
        >
          Input teammate’s details below
        </p>

        <hr />
        <form className='form-borders' onSubmit={handleSubmit(onSubmit)}>
          <div className='flex-row '>
            <div>
              <label>First Name *</label>
              <input
                type='text'
                {...register('first_name')}
                placeholder='Type here...'
              />
              {errors.first_name && (
                <p style={{ color: '#FD483D', fontSize: '12px' }}>
                  {errors.first_name.message}
                </p>
              )}
            </div>
            <div>
              <label>Last Name *</label>
              <input
                type='text'
                {...register('last_name')}
                placeholder='Type here...'
              />
              {errors.last_name && (
                <p style={{ color: '#FD483D', fontSize: '12px' }}>
                  {errors.last_name.message}
                </p>
              )}
            </div>
          </div>
          <div className='flex-row'>
            <div>
              <label>Work Email Address *</label>
              <input
                type='text'
                {...register('email')}
                placeholder='Type here...'
              />
              {errors.email && (
                <p style={{ color: '#FD483D', fontSize: '12px' }}>
                  {errors.email.message}
                </p>
              )}
            </div>
            <div>
              <label>Position *</label>
              <select
                id='select'
                style={{ border: '1px solid #d6d6d6' }}
                {...register('position')}
                onChange={(e) => setValue('position', e.target.value)}
              >
                <option value=''>Select...</option>
                {['Email'].map((role, i) => (
                  <option key={i} value={role}>
                    {role}
                  </option>
                ))}
              </select>
              {errors.position && (
                <p style={{ color: '#FD483D', fontSize: '12px' }}>
                  {errors.position.message}
                </p>
              )}
            </div>
          </div>
          <hr />
          <button
            type='submit'
            className='update'
            style={{ width: '50%', marginLeft: '10rem' }}
            disabled={mutation?.isPending}
          >
            {mutation?.isPending ? (
              <RotatingLines
                type='Oval'
                style={{ color: '#FFF' }}
                height={20}
                width={20}
              />
            ) : (
              <>Add Email</>
            )}
          </button>
        </form>
      </div>
      {/* <Modal
        isOpen={modalIsOpenSuccess}
        onRequestClose={closeSuccessModal}
        contentLabel='Delete Modal'
        className='custom-modal-success-two'
        overlayClassName='custom-overlay'
      >
        <div className='succes-modal-content'>
          <div className='success-icon icon-with-bg'>
            <div className='circle'>
              <div className='checkmark'></div>
            </div>
            :
          </div>
          <h4
            className='text-center'
            style={{ color: '#262626', fontSize: '40px' }}
          >
            Successful
          </h4>
          <p className='text-center' style={{ color: '#262626' }}>
            You have successfully invited a teammate.
          </p>
        </div>
      </Modal> */}
    </div>
  )
}

export default SettingsAddEmailNotificationModal
